import React, { useEffect } from "react";
import { Container } from "reactstrap";
import hector from "../../assets/images/hector.jpg";
import csharp from "../../assets/images/csharp.ico";
import sql from "../../assets/images/sql.ico";
import react from "../../assets/images/logo192.png";

function ProfilePageHeader() {
  let pageHeader = React.createRef();

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        filter-color="blue"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/bg5.jpg") + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="photo-container">
            <img alt="HM Logo" src={hector}></img>
          </div>
          <h3 className="title">Hector Murua</h3>
          <p className="category">Full Stack Software Engineer</p>
          <div className="content">
            <div className="social-description">
              <img
                alt="..."
                src={react}
                width="64"
                height="64"
                className="App-logo"
              ></img>
            </div>
            <div className="social-description">
              <img alt="..." src={csharp}></img>
            </div>
            <div className="social-description">
              <img alt="..." src={sql}></img>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ProfilePageHeader;
