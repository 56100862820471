import "./App.css";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import LandingPage from "./components/landingpage/LandingPage";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import Dimension from "components/testthemes/Dimension";
import LoginPage from "components/login/Login";

const loading = () => <div className="">loading....</div>;

function App() {
  return (
    <div>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/dimension" element={<Dimension />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
