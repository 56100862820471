import React, { useEffect } from "react";
import { Button, Container } from "reactstrap";
import MainNavbar from "../navbar/MainNavbar";
import ProfilePageHeader from "../header/ProfileHeader";
import ProfileFooter from "../footers/ProfileFooter";
import html from "../../assets/images/html.ico";
import css from "../../assets/images/css.ico";
import javascript from "../../assets/images/javascript.ico";
import node from "../../assets/images/node.ico";
import react from "../../assets/images/react.ico";
import git from "../../assets/images/git.ico";
import csharp from "../../assets/images/csharp.ico";
import sql from "../../assets/images/sql.ico";
import swal from "sweetalert";

function Landing() {
  // const [modal2, setModal2] = React.useState(false);

  useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  // const onRun = () => {
  //   swal("Got away safely!", "success");
  //   return;
  // };

  const clickHandler = () => {
    swal("A wild Pikachu appeared! What do you want to do?", {
      buttons: {
        cancel: "Run away!",
        catch: {
          text: "Throw Pokéball!",
          value: "catch",
        },
        defeat: true,
      },
    }).then((value) => {
      switch (value) {
        case "defeat":
          swal("Pikachu fainted! You gained 500 XP!");
          break;

        case "catch":
          swal("Gotcha!", "Pikachu was caught!", "success");
          break;

        default:
          swal("Got away safely!");
      }
    });
    return;
  };

  return (
    <>
      <MainNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container>
            <h3 className="title">About Me</h3>
            <h5 className="description text-dark">
              "I am a software engineer with over a decade of experience in the
              industry. I have honed my skills by working on a variety of
              projects in advanced electronics and computer systems, which has
              proven to be a highly rewarding and fulfilling experience. I am
              passionate about staying current with the latest advancements in
              technology, and I am always seeking opportunities to expand my
              knowledge and grow as a professional. In addition, my military
              service and entrepreneurial experience have provided me with a
              strong foundation in discipline, leadership, and professionalism
              that I have been able to apply in my work as a software engineer."
            </h5>
            <div className="content text-center">
              <div className="social-description">
                <img alt="..." src={html}></img>
              </div>
              <div className="social-description">
                <img alt="..." src={css}></img>
              </div>
              <div className="social-description">
                <img alt="..." src={javascript}></img>
              </div>
            </div>
            <div className="content text-center">
              <div className="social-description">
                <img alt="..." src={node}></img>
              </div>
              <div className="social-description">
                <img alt="..." src={react}></img>
              </div>
            </div>
            <div className="content text-center">
              <div className="social-description">
                <img alt="..." src={git}></img>
              </div>
              <div className="social-description">
                <img alt="..." src={csharp}></img>
              </div>
              <div className="social-description">
                <img alt="..." src={sql}></img>
              </div>
            </div>
            <div className="content text-center">
              <Button className="m-5" color="info" onClick={clickHandler}>
                Click Me!
              </Button>
            </div>
            {/*
            <Button color="info" onClick={() => setModal2(true)}>
              Click Here!
            </Button>
            <Modal
              modalClassName="modal-mini modal-info"
              toggle={() => setModal2(false)}
              isOpen={modal2}
            >
              <div className="modal-header justify-content-center">
                <div className="modal-profile">
                  <i className="now-ui-icons users_circle-08"></i>
                </div>
              </div>
              <ModalBody>
                <p>"A wild Pikachu appeared! What do you want to do?"</p>
              </ModalBody>
              <div className="modal-footer">
                <Button
                  className="btn-neutral"
                  color="link"
                  type="button"
                  onClick={onRun}
                >
                  "Run away!"
                </Button>
                <Button
                  className="btn-neutral"
                  color="link"
                  type="button"
                  onClick={() => setModal2(false)}
                >
                  Close
                </Button>
              </div>
            </Modal>
  */}
          </Container>
        </div>
        <ProfileFooter />
      </div>
    </>
  );
}

export default Landing;
