import React from "react";
// import debug from "sabio-debug";
import { Button } from "reactstrap";

// const _logger = debug.extend("Dimension");
function Dimension() {
  const submitHandler = (e) => {
    // _logger("I was clicked", e);
  };

  return (
    <div>
      <Button onClick={submitHandler} color="primary">
        {" "}
        Click Me!
      </Button>
    </div>
  );
}

export default Dimension;
