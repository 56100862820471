import React from "react";
import { Container } from "reactstrap";

function TransparentFooter() {
  return (
    <footer className="footer">
      <Container>
        {/* <nav>
          <ul>
            <li>
              <a
                href="https://github.com/HectorMurua"
                rel="noopener noreferrer"
                target="_blank"
                className="fab fa-github"
                style={{ fontSize: "20px" }}
              >
                {" "}
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/hectormuruajr/"
                rel="noopener noreferrer"
                target="_blank"
                className="fab fa-linkedin"
                style={{ fontSize: "20px" }}
              >
                {" "}
              </a>
            </li>
          </ul>
        </nav> */}
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Designed & Created by{" "}
          <a href="/">Hector Murua</a>
        </div>
      </Container>
    </footer>
  );
}

export default TransparentFooter;
